import { definePlugin } from '/@src/app'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

export let appInsights: ApplicationInsights

export default definePlugin(() => {
  if (import.meta.env.PROD) {
    appInsights = new ApplicationInsights({ config: {
      connectionString: 'InstrumentationKey=86fb8032-7847-4194-a155-4ad36c157b23;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/;ApplicationId=3a601348-2678-4bcd-a492-92c47460f830',
    } })

    appInsights?.loadAppInsights()

    console.log('App Insights initialized')
  }
})
